<template>
<div class="content">
    <div class="center">
        <!-- 团长 -->
        <div v-for="(item,index) in Leader" :key="index" class="center-content">
              <!-- v-if="item.type=='团长'" -->
             <span class="type2" ></span>               
             <span class="type_1">
                  团长                 
                 </span>
               <span class="imgs">
                   <img class="img" :src="item.img" @click="see(item.UserID)">
               </span>
               <span class="name">{{item.name}}</span>
               <span class="time">{{item.joinTime}}入团</span>
        </div>
        <!-- 副团长 -->
        <div v-for="(item,index) in deputy" :key="index" class="center-content">
            
             <span class="type"></span>              
             <span class="type_1">
                  副团长                  
                 </span>
               <span class="imgs">
                   <img class="img" :src="item.img" @click="see(item.UserID)">
               </span>
               <span class="name">{{item.name}}</span>
               <span class="time">{{item.joinTime}}入团</span>
        </div>
</div>

    </div>
       
</template>
<script>
import {QUERY,UPDATE,DELETE,QUERYED}  from '@/services/dao.js';
import { formatDate,showTimeFormat} from '@/js/common';
export default {
    data(){
        return{
             Leader:[],
             deputy :[],
             number:0
        }
    },
    created(){
        //   this.now=new Date().getTime();
        this.Leaders()
        ///副团长
        this.deputysed();
    },
    methods:{
     //查看团队成员的回答列表
    async see(UserID){
        this.$router.push({
            path:"/answer-list"
            ,query:{
            id:UserID
            }
        })
        },
        //团长
   async Leaders(){
    this.number=this.$route.query.id;
    //alert(this.number)
    let data=  await   QUERYED("post","",'   WDMemberTeam(where: {Leader: {_eq: "团长"}, id: {_eq: '+this.number+'}}) { userid img  name    joinTime  Leader  }');
    console.log(data)
    this.Leader.splice(0, this.Leader.length);
      for (let i = 0; i < data.data.WDMemberTeam.length; i++) {
        this.Leader.push({
            UserID:data.data.WDMemberTeam[i].userid,
            img: this.api.LoginURL.concat(data.data.WDMemberTeam[i].img),
            name: data.data.WDMemberTeam[i].name,
            joinTime:  formatDate(Date.parse(data.data.WDMemberTeam[i].joinTime)),
        });
      }  

   },
   ///////副团长
   async deputysed(){
    this.number=this.$route.query.id;
    //alert(this.number)
    let data=  await   QUERYED("post","",'   WDMemberTeam(where: {id: {_eq: '+this.number+'}, _not: {Leader: {_eq: "团长"}}}, limit: 3)  { userid img  name    joinTime  Leader  }');
    console.log(data)
    this.deputy.splice(0, this.deputy.length);
      for (let i = 0; i < data.data.WDMemberTeam.length; i++) {
        this.deputy.push({
            UserID:data.data.WDMemberTeam[i].userid,
            img: this.api.LoginURL.concat(data.data.WDMemberTeam[i].img),
            name: data.data.WDMemberTeam[i].name,
            joinTime:  formatDate(Date.parse(data.data.WDMemberTeam[i].joinTime)),
        });
      } 
   }
    }
}
</script>
<style scoped>
.center{
     /* margin: auto; */
    width: 93%;
    display: inline-block;
    /* border: 1px solid red; */
    margin-top: 40px;
    margin-bottom: 40px;
}
.content{
    text-align:center;
    width: 100%;
    /* padding: 40px;     */
    background-color: white;
}
.center-content{
    padding-top: 10px;
    /* padding-left: 10px; */
    width: 210px;
    /* display:inline-block; */
    float: left;
    margin: auto;
    border:1px solid rgba(220,220,220,1);
    margin-right: 60px;
    margin-left: 50px;
    margin-bottom: 40px;
    position: relative;

    /* 三角形 */
}
.imgs{
    margin-top: 30px;
    margin-bottom: 10px;
}
.imgs,.name{
    text-align: center;
    width: 100%;
    display: inline-block;
    /* border: 1px solid green; */
}
.img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
}
/* .name{
    height: 50px;
    line-height: 50px;
} */
.center_num{
    width: 100%;
    height: 80px;
    background:rgba(238,238,238,1);
    display: inline-block;
    text-align: center;
    padding-top: 20px;
    /* line-height: 100px; */
}
.type{	
    width: 0px;
    height: 0px;
    border-top: 70px solid #09A25F;
    border-right: 70px solid transparent;
    position: absolute;
    top: -1px;
    left: -1px;
     /* transform: rotate(45deg); */
    /* display: inline-block; */
}
.type2{
    width: 0px;
    height: 0px;
    border-top: 70px solid rgba(229,3,3,1);
    border-right: 70px solid transparent;
    position: absolute;
    top: -1px;
    left: -1px;
}
.type_1{
    /* width: 30px;
    height: 30px; */
    /* border: 1px solid red; */
    color: white;
    transform: rotate(-45deg);
    position: absolute;
    display: inline-block;
    font-size: 17px;
    /* top: -80px; */
}
.time{
    width: 100%;
    text-align: center;
    display: inline-block;
    color: #8B8A8A;
    margin-bottom: 20px;
    font-size: 12px;
    margin-top: 10px;
}
</style>